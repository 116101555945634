/* eslint-disable prefer-regex-literals */
export function useLazyAsyncFetch<T> (fullpath: string, init?: RequestInit) {
  const config = useRuntimeConfig()
  const url = config.public.baseUrl + '/' + fullpath.replace(/^\/+/g, '')

  const { data: response } = useLazyAsyncData('response', async () => {
    const response = await fetch(url, init)
    return await response.json() as T
  })

  return response
}

export function clearObjectValues (obj: Record<string, any>): Record<string, any> {
  const keys = Object.keys(obj)

  keys.forEach((key) => {
    obj[key] = null
  })

  return obj
}

export function getElementCode () {
  return new Date().getTime().toString() + Math.floor(Math.random() * 10).toString()
}

/*
  パスワード強度判定
  1:弱い 2:やや弱い 3:普通 4:やや強い 5:強い
*/
export function getPasswordLevel (password: string): number {
  let level = 0
  let pattern = 0
  let hasLower = false
  let hasUpper = false
  let hasCharacter = false
  let hasNumber = false

  for (let i = 0; i < password.length; i++) {
    const ascii = password.charCodeAt(i)

    // アルファベット小文字チェック
    if ((ascii >= 97) && (ascii <= 122)) {
      hasLower = true
    }

    // アルファベット大文字チェック
    if ((ascii >= 65) && (ascii <= 90)) {
      hasUpper = true
    }

    // 数値チェック
    if ((ascii >= 48) && (ascii <= 57)) {
      hasNumber = true
    }

    // 記号チェック
    if (((ascii >= 33) && (ascii <= 47)) ||
        ((ascii >= 58) && (ascii <= 64)) ||
        ((ascii >= 91) && (ascii <= 96)) ||
        ((ascii >= 123) && (ascii <= 126))) {
      hasCharacter = true
    }
  }

  // パターン判別
  if (hasLower) { pattern++ }
  if (hasUpper) { pattern++ }
  if (hasNumber) { pattern++ }
  if (hasCharacter) { pattern++ }

  // パスワードレベル判定

  // 辞書に登録されている文字チェック
  const dictionary = ['password', 'qwerty', 'abc', 'admin', 'root', '123', 'user']

  for (let i = 0; i < dictionary.length; i++) {
    if (password.includes(dictionary[i])) {
      level = 1
      return level
    }
  }

  // 数値のみパスワードチェック
  if (password.match(/^[0-9]+$/)) {
    level = 1
    return level
  }

  if (password.length < 8) {
    level = 1
  }
  if ((password.length >= 8) && (password.length < 14)) {
    level = 2
  }
  if ((password.length >= 8) && (password.length < 14) && (pattern >= 2)) {
    level = 3
  }
  if ((password.length >= 8) && (password.length < 14) && (pattern >= 3)) {
    level = 4
  }
  if ((password.length >= 14) && (pattern < 3)) {
    level = 3
  }
  if ((password.length >= 14) && (pattern >= 3)) {
    level = 5
  }

  return level
}

export function isEmail (value: string): boolean {
  if (typeof value !== 'string') {
    return false
  }

  // @マークが 前方一致しない 且つ 後方一致しない 且つ メールアドレスに有効な文字列 且つ @が含まれている場合のみ 通過させる
  if (
    !RegExp('^@.*$').test(value) &&
    !RegExp('.*@$').test(value) &&
    // eslint-disable-next-line no-useless-escape
    RegExp(/^[a-zA-Z0-9!#\$%&'\*\+\-\.\/=\?@\^_`{\|}~]*$/).test(value) &&
    RegExp('@').test(value)
  ) {
    return true
  }

  return false
}
